import React, {useEffect, useState} from 'react';
import {Button} from "reactstrap";
import {MILLISECOND_IN_SECOND, SECOND_IN_MINUTES} from "../../const/time";
import {noOperation} from "../../helpers/Utils";

const TimerButton = ({ label, onClick = noOperation, className, onRenderStartTimer = false, restart }) => {
    const [seconds, setSeconds] = useState(onRenderStartTimer ? SECOND_IN_MINUTES : 0)
    const [disabled, setDisabled] = useState(onRenderStartTimer)

    useEffect(() => {
        const interval = setInterval(() => {
            disabled && setSeconds(time => time >= 1 ? time - 1 : 0)
        }, MILLISECOND_IN_SECOND)
        if(seconds === 0) setDisabled(false)
        return () => clearInterval(interval)
    }, [disabled, seconds])

    useEffect(() => {
        if (restart) {
            setDisabled(true)
            setSeconds(SECOND_IN_MINUTES)
        }
    }, [restart])

    return (
        <Button
            color='link'
            className={className}
            type='button'
            onClick={() => {
                onClick();
                setDisabled(true)
                setSeconds(SECOND_IN_MINUTES)
            }}
            disabled={disabled}
        >
            {disabled && <span>({seconds}) </span>}
            {label}
        </Button>
    );
};

export default TimerButton;