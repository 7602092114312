export const WORKOUT = '/workout-programs';
export const COACHING = '/coaching-plans';
export const TRAINERS = "/trainers";
export const REVIEWS = '/reviews'
export const QUESTIONS = '/questions'
export const QUESTIONS_REPLIES = '/questions/replies'
export const PHOTO = '/photo-progress-requests'

export const BASE_REDIRECT_TO_APP = process.env.NEXT_PUBLIC_BASE_REDIRECT_TO_APP + '/redirect';

export const BASE_REDIRECT = BASE_REDIRECT_TO_APP + "/checkout?";
export const WORKOUT_REDIRECT ='workoutProgramId=';
export const COACHING_REDIRECT = 'coachingPlanId=';

export const DEFAULT_ORDER = 'popular';
export const ASC = 'asc';
export const DESC = 'desc';