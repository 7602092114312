import React from 'react';
import IconSvg from "../../IconSVG";
import {InputGroupText} from "reactstrap";

const InputGroupIcon = ({ icon, fill }) => {
    return <InputGroupText className="input-icon non-bc">
        <IconSvg fill={ fill } { ...icon }/>
    </InputGroupText>;
};

export default InputGroupIcon;