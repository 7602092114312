export const MILLISECOND_IN_SECOND = 1000;
export const SECOND_IN_MINUTES = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;

export const timeData = [
    {label:'seconds', time: MILLISECOND_IN_SECOND},
    {label:'minutes', time: SECOND_IN_MINUTES},
    {label:'hours', time: MINUTES_IN_HOUR},
    {label:'days', time: HOURS_IN_DAY},
    {label:'months', time: 30},
    {label:'years', time: 12}
];

export const timeDataPhoto = [
    {label:'seconds', time: MILLISECOND_IN_SECOND},
    {label:'minutes', time: SECOND_IN_MINUTES},
    {label:'hours', time: MINUTES_IN_HOUR},
    {label:'days', time: HOURS_IN_DAY},
    {label: 'weeks', time: DAYS_IN_WEEK},
    {label: 'months', time: 4},
]