import React from 'react';

import {ModalBody, Button, Form, FormGroup} from "reactstrap";
import { Formik } from "formik";
import CheckButton from "../filters/components/CheckButton"
import * as Yup from 'yup'
import styles from "./modal.module.scss"
import {password, email} from "../../const/icons";

import FormikFormGroup from "./formik/FormikFormGroup";
import {
    IS_INCORRECT_PASSWORD, IS_NOT_VALID_FORMAT, IS_REQUIRED_FIELD,
    IS_REQUIRED_PASSWORD,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    PASSWORD_PATTERN
} from "../../const/message";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(IS_NOT_VALID_FORMAT)
        .required(IS_REQUIRED_FIELD),
    password: Yup.string()
        .min(PASSWORD_MIN_LENGTH, IS_INCORRECT_PASSWORD)
        .max(PASSWORD_MAX_LENGTH, IS_INCORRECT_PASSWORD)
        .matches(PASSWORD_PATTERN, IS_INCORRECT_PASSWORD)
        .required(IS_REQUIRED_PASSWORD),
});

const initialValues = {
    email: "",
    password: "",
    remember: false
};

const SignIn = ({toggle, onSubmit, forgotPasswordClick}) => {
    return (
        <div className='auth__modal'>
            <ModalBody className="text-center">
                <h2 className={styles.modal__head}>Sign In to your Trainest account</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, isValid, values, isTouched, handleSubmit }) => (
                        <Form className="w-100" onSubmit={handleSubmit}>
                            <FormikFormGroup
                                errors={errors}
                                values={values}
                                className="mb-4 mt-1"
                                touched={touched}
                                fieldName="email"
                                placeholder="Email"
                                icon={email}
                            />
                            <FormikFormGroup
                                values={values}
                                errors={errors}
                                touched={touched}
                                fieldName="password"
                                placeholder="Password"
                                icon={password}
                                type="password"
                            />
                            <FormGroup>
                                <div className={styles.form__group}>
                                    <label className={styles.filter__checkbox}>
                                        <CheckButton
                                            name="remember"
                                            id="remember"
                                            onChange={() => values.remember = !values.remember}
                                        />
                                        <span className={styles.checkbox__label}>Remember me</span>
                                    </label>

                                    <Button
                                        color='link'
                                        type='button'
                                        className={styles.forgot__pass}
                                        onClick={forgotPasswordClick}
                                    >
                                        Forgot password?
                                    </Button>
                                </div>
                            </FormGroup>
                            <div className="d-flex justify-content-center align-items-center">
                                <Button
                                    color="primary"
                                    disabled={isValid && isTouched}
                                    className={styles.btn}
                                    size="lg"
                                    type="submit"
                                >
                                    Sign In
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className={styles.modal__footer}>
                    Dont have an account?
                    <Button 
                        color="link" 
                        onClick={toggle}
                        className={styles.btn__link}
                    >
                        Sign Up
                    </Button>
                </div>
            </ModalBody>
        </div>
    );
};

export default SignIn;