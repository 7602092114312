import React, {useEffect, useState} from 'react';
import {joinClassNames, noOperation} from "../../helpers/Utils";
import {Collapse, Button, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem} from "reactstrap";
import Image from "next/legacy/image";
import Link from "next/link";
import {useRouter} from 'next/router'

import menuItems from "../../const/menuItems"

import IcLogo from '../../icons/home/ic-logo-medium.svg'
import IcLogoMobile from "../../icons/home/ic-logo-icon.svg";

import styles from './header.module.scss'

import SignIn from "../modal/SignIn";
import SingUp from "../modal/SingUp";
import PopupWrapper from "../modal/PopapWrapper";
import {activePopup} from "../modal/actions";
import observable from "../../helpers/observer";
import {OPEN_LOGIN} from "../../const/observerKey";
import SearchLanding from './SearchLanding';
import {login, updateTimezoneOffset} from "../../services/WorkoutProgramService";
import {BASE_REDIRECT_TO_APP} from "../../const/services/API";
import {getCookie, setCookie} from "../../helpers/cookies";
import {REDIRECT_COOKIES, SESSIONS_COOKIES, USER_COOKIES} from "../../const/storege";
import {NotificationManager} from "../react-notifications";
import {ERROR, INCORRECT_AUTH} from "../../const/message";
import {useModal} from "../../hooks/useModal";
import TwoStepVerify from "../modal/TwoStepVerify";
import appRedirectsValue from "../../const/services/appRedirectsValue";
import ROLE from '../../const/role'
import ForgotPassword from "../modal/ForgotPassword";
import AccountBlocked from "../modal/AccountBlocked";
import {ACCOUNT_WAS_BLOCKED, getErrorTitleInResponse, NOT_VERIFIED} from "../../helpers/stacktraceErrors";

const errorCallbackSignInFactory = {
    [NOT_VERIFIED]: ({ email }) => {
        window.location = `${process.env.NEXT_PUBLIC_BASE_REDIRECT_TO_APP}/auth/verification?email=${email}`
    },
    [ACCOUNT_WAS_BLOCKED]: ({ toggleBlocked, setIsOpenLogin }) => {
        toggleBlocked();
        setIsOpenLogin()
    }
}

const Header = ({ dark = false}) => {
    const [isOpenNav, setIsOpenNav] = useState(false);
    const {isOpen: isOpenLogin, toggle: setIsOpenLogin} = useModal();
    const {isOpen: isOpenRegister, toggle: setIsOpenRegister} = useModal();
    const {isOpen: isOpenTwoStepVerify, toggle: toggleTwoStepVerify} = useModal();
    const {isOpen: isOpenForgotPassword, toggle: toggleForgotPassword} = useModal();
    const {isOpen: isOpenBlocked, toggle: toggleBlocked} = useModal();
    const [openSearchVersion, setOpenSearchVersion] = useState(false)
    const [credentials, setCredentials] = useState({})

    const [sessions, setSessions] = useState({})
    const [phone, setPhone] = useState('700')

    const router = useRouter();

    const onUserLogin = async (values) => {
        try {
            const { session, user } = await login(values);
            const link = BASE_REDIRECT_TO_APP;

            !getCookie(REDIRECT_COOKIES) && setCookie(REDIRECT_COOKIES, appRedirectsValue.login)

            if(!session.isTwoFactorCompleted && user.role === ROLE.TRAINER){
                setIsOpenLogin()
                toggleTwoStepVerify()
                setSessions(session)
                setPhone(user.phone)
                setCredentials(values)
                return
            }

            setCookie(SESSIONS_COOKIES, session);
            setCookie(USER_COOKIES, {id: user.id})
            updateTimezoneOffset(session.accessToken)
            setTimeout(() => window.location = link, 1000)

        } catch (error) {
            const callback = errorCallbackSignInFactory[getErrorTitleInResponse(error)]
            if (!!callback) {
                callback({ toggleBlocked, setIsOpenLogin, email: values.email });
                return
            }
            const message = error.response.data?.errors[0]?.message || INCORRECT_AUTH
            NotificationManager.warning(message, ERROR)
        }
    };

    function onToggleMenu() {
       setIsOpenLogin()
       setIsOpenRegister()
    }

    const handleForgotPassword = () => {
        toggleForgotPassword();
        setIsOpenLogin();
    }

    useEffect(() => {
        observable.subscribe(OPEN_LOGIN, () => activePopup(isOpenLogin, setIsOpenLogin))
        return observable.unsubscribe(OPEN_LOGIN, noOperation)
    },[]);

    useEffect(() => {
        if(router.pathname === '/')
            return setOpenSearchVersion(false)
        return setOpenSearchVersion(true)
    }, [router.pathname])

    return (
        <div className="header">
            <Navbar
                light
                expand="md"
                className={joinClassNames('align-items-center', 'flex-nowrap', dark && "navbar-dark", isOpenNav && "open")}
            >
                <NavbarBrand tag={Link} href="/">
                    <div className="logo__link mr-md-2">
                        <span className="d-none d-lg-block">
                            <Image
                                src={IcLogo}
                                alt="Logo"
                                priority
                            />
                        </span>

                        <span className="d-block d-lg-none">
                            <Image
                                src={IcLogoMobile}
                                alt="Logo"
                                priority
                            />
                        </span>
                    </div>
                </NavbarBrand>

                {openSearchVersion && <SearchLanding/>}

                <NavbarToggler onClick={() => setIsOpenNav(!isOpenNav)} className="ml-2 navbar-dark border-0" />

                <Collapse isOpen={isOpenNav} navbar>
                    <Nav className="mr-auto ml-auto" navbar>
                        {!openSearchVersion && menuItems.map(item => (
                            <NavItem key={item.id}>
                                <Link
                                    href={{pathname: item.to}}
                                    id={item.label}
                                    className={styles.nav__link}
                                    onClick={() => setIsOpenNav(false)}>

                                    {item.label}

                                </Link>
                            </NavItem>
                        ))}
                    </Nav>
                    <div className="buttons d-flex">
                        <Button
                            onClick={() => activePopup(isOpenRegister, setIsOpenRegister)}
                            className={joinClassNames(styles.btn__signUp, styles.btn)}>
                            Sign Up
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => activePopup(isOpenLogin, setIsOpenLogin)}
                            className={joinClassNames(styles.btn__signIn, styles.btn)}>
                            Sign In
                        </Button>
                    </div>
                </Collapse>
            </Navbar>
            <PopupWrapper onActive={isOpenLogin} toggleMenu={()=>activePopup(isOpenLogin, setIsOpenLogin)}>
                <SignIn toggle={onToggleMenu} onSubmit={onUserLogin} forgotPasswordClick={handleForgotPassword}/>
            </PopupWrapper>
            <PopupWrapper onActive={isOpenRegister} toggleMenu={()=>activePopup(isOpenRegister, setIsOpenRegister)}>
                <SingUp toggle={onToggleMenu}/>
            </PopupWrapper>

            <PopupWrapper onActive={isOpenTwoStepVerify} toggleMenu={toggleTwoStepVerify}>
                <TwoStepVerify sessions={sessions} phone={phone} onResendClick={() => login(credentials)}/>
            </PopupWrapper>

            <PopupWrapper onActive={isOpenBlocked} toggleMenu={toggleBlocked}>
                <AccountBlocked toggle={toggleBlocked}/>
            </PopupWrapper>

            <PopupWrapper onActive={isOpenForgotPassword} toggleMenu={toggleForgotPassword}>
                <div className='forgot-password-modal'>
                    <ForgotPassword onDoneClick={toggleForgotPassword}/>
                </div>
            </PopupWrapper>
        </div>
    );
};

export default Header