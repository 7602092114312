export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT_FILTER = 16;
export const DEFAULT_LIMIT_QUESTIONS = 5;
export const DEFAULT_LIMIT_PHOTO = 3;
export const CENTS_IN_DOLLAR = 100;

export const DEFAULT_MIN_LENGTH = 1;
export const DEFAULT_LENGTH_TEXTAREA = 500;
export const MAX_LENGTH_QUESTION = 3000;
export const NOT_SPACES = "Can not contain spaces only.";
export const DEFAULT_PHOTO_PAGE_LIMIT = 25;
export const DEFAULT_LIMIT_REVIEWS = 20;