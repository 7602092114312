import React from "react";
import {getDeepValue, joinClassNames} from "../../../helpers/Utils";

const FormikFormError = ({ children, className }) => {
    return <div className={joinClassNames("d-block is-invalid", className)}>
        { children }
    </div>;
};

const FormErrors = ({ fieldName, errors, touched, className = '' }) => {
    const error = getDeepValue(errors, fieldName);
    const isTouched = getDeepValue(touched, fieldName);

    if (error && isTouched) return (
        <FormikFormError className={className}>
            {error}
        </FormikFormError>
    );

    return null;
};

export default FormErrors;