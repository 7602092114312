export function addScroll ()
{
    document.body.classList.add('hidden');
}
export function closeScroll ()
{
    document.body.classList.remove('hidden');
}

export function activePopup(isOpen, setOpen){
    if(isOpen)
        closeScroll();
    if(!isOpen)
        addScroll();
    setOpen(!isOpen)
}