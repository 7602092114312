export const IS_REQUIRED_FIELD = "Field is required";
export const IS_NOT_VALID_FORMAT = "Invalid format";
export const IS_REQUIRED_OTP = "Enter OTP";

export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 50;

export const IS_REQUIRED_PASSWORD = "Enter your password";
export const PASSWORD_DOES_NOT_MATCH = "Password does not match";
export const IS_INCORRECT_PASSWORD = "Password is incorrect";

export const IS_INCORRECT_FIRST_NAME = "First Name is incorrect";
export const IS_INCORRECT_LAST_NAME = "Last Name is incorrect";
export const IS_INCORRECT_NICK_NAME = "Nickname is incorrect";
export const IS_INCORRECT_NICK_NAME_LENGTH = "Nickname should be at least 3 symbols";

export const NICKNAME_MIN_LENGTH = 3;
export const NICKNAME_MAX_LENGTH = 20;

export const QUESTION_REQUIRED = 'Question can not contain spaces only';

export const IS_MANDATORY_FIRST_NAME = "First name is mandatory";
export const IS_MANDATORY_LAST_NAME = "Last name is mandatory";

export const FIRST_NAME_MIN_LENGTH = 1;
export const FIRST_NAME_MAX_LENGTH = 50;

export const LAST_NAME_MIN_LENGTH = 1;
export const LAST_NAME_MAX_LENGTH = 50;

export const UNDEFINED = "undefined";

export const DOT = "dot";
export const SLASH = "slash";
export const NUMBER = "number";

export const ERROR = 'Error';
export const INCORRECT_AUTH = 'Incorrect email or password';
export const INCORRECT_EMAIL = 'Incorrect email';
export const REPORT_SUCCESS = 'Report submitted successfully!';
export const QUESTION_SUCCESS = 'Question submitted successfully!';
export const REPORT_TIME = 'You can submit a report once per 1 minute. Please, try again later';