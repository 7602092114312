import React from "react";
import Select from "react-select";

import {joinClassNames, noOperation} from "../../helpers/Utils";

export const BaseSelect = ({
                               name,
                               value,
                               className,
                               options = [],
                               isMulti = false,
                               disabled = false,
                               fullOption = false,
                               isSearchable = false,
                               callback = noOperation,
                               onBlur = noOperation,
                               onChange = noOperation,
                           }) => (
    <Select
        className={joinClassNames("react-select", className)}
        classNamePrefix="react-select"
        options={options}
        isMulti={isMulti}
        name={name}
        isSearchable={isSearchable}
        isDisabled={disabled}
        value={options ? options.find(option => option.value === value) : ''}
        onChange={option => {
            const selected = fullOption ? option : option.value;
            onChange(selected);
            callback(selected);
        }}
        onBlur={onBlur}
    />
);