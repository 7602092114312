import {CENTS_IN_DOLLAR} from "../const/services/defaultValue";
import {timeData, SECOND_IN_MINUTES} from "../const/time";
import moment from "moment";
import purify from 'isomorphic-dompurify'
import {setCookie} from "./cookies";
import observable from "./observer";
import {OPEN_LOGIN} from "../const/observerKey";
import {AUTH_LOCALSTORAGE, REDIRECT_COOKIES} from "../const/storege";
import {NotificationManager} from "../components/react-notifications";

export const TOTAL_PERCENT = 100;

export const formatPriceToCents = (value) => {
    if (value)
        return Math.round(value * CENTS_IN_DOLLAR);
    return undefined
};

export function deleteProp(data, prop) {
    const { [prop]: remove, ...rest } = data;
    return rest;
}

export const getExperiencePeriod = ({ from, to }) => {
    const MONTH_IN_YEAR = 12;
    const monthCount = Math.abs(moment(from).diff(moment(to), "month"));
    const durationMessage =
        monthCount > MONTH_IN_YEAR
            ? `${Math.floor(monthCount / MONTH_IN_YEAR)} yrs`
            : `${monthCount} month`;

    return `
        ${moment(from).format("YYYY")} - ${
        !to ? "present" : moment(to).format("YYYY")
    } (${durationMessage})
    `;
};

export const formatPrice = (value) => {
    return (value / CENTS_IN_DOLLAR).toFixed(2);
};

export const getDeepValue = (object, path) =>
    path
        .replace(/\[|\]\.?/g, ".")
        .split(".")
        .filter((s) => s)
        .reduce((acc, val) => acc && acc[val], object);

export const sortByTextField = (array, field) =>
    array.sort((prev, next) =>
        getDeepValue(prev, field) > getDeepValue(next, field) ? 1 : -1
    );

export function joinClassNames(...classNames) {
    return classNames.join(' ');
}

export function addSymbolDollar(text) {
    return '$'+text;
}

export function calcPageCount(total, limit) {
    return Math.ceil(total/ limit)
}

export const cropMani = (data, string) => {
    if(+data === 1) return string.substring(0, string.length - 1)

    return string
}

export function timeDifference(current, previous, timeDataArray = timeData) {
    let elapsed = current - previous;
    for(let i = 0; i < timeDataArray.length - 1; i++){
        elapsed = Math.round(elapsed / timeDataArray[i].time);
        if(elapsed < timeDataArray[i + 1].time)
            return `${elapsed} ${cropMani(elapsed, timeDataArray[i].label)}`;
    }
    return `${elapsed} ${timeDataArray[timeDataArray.length - 1].label}`;
}

export function timeAgo(createdAt) {
    return moment(createdAt);
}
export const noOperation = () => {};
export const noOperationWithData = (value) => value;

export const convertToArray = (value) => {
    if(!value) return [];
    if(!Array.isArray(value)) return [value];
    return value
};

export const getPercent = (value, total) => {
    if(!total) return 0
    return Math.round(value / total * TOTAL_PERCENT)
}

export const getIdInString = item => item.id.toString();

export const isIncludes = (array, value) => {
    return convertToArray(array).includes(value)
};

export const arrayWithUnicElement = (array1, array2) => {
    const set = new Set([...array1, ...array2]);
    return Array.from(set)
};

export const arraysDifference = (arrayMinuend, arraySubtrahend) => {
    return arrayMinuend.reduce((acc, item) => {
        const isIncludeInSelected = arraySubtrahend.includes(item);
        if (!isIncludeInSelected) acc.push(item);
        return acc;
    }, [])
};

export const inclusion = (set, subset) => {
    return subset.every(item => isIncludes(set, item))
};

export const convertSecondToMinute = time => Math.floor(time / SECOND_IN_MINUTES) +' min';

export const convertTimeToRangeMinutes = value => value.split('-').map(convertSecondToMinute).join(' - ');

export function objectsEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  export function urlWithoutQuery(url){
    const pathNameIndex = url.indexOf('?');
    if(pathNameIndex > 0) return url.slice(0, pathNameIndex);
    return url;
  }


export function addScroll ()
{
    document.body.classList.add('overflow-auto');
}
export function removeScroll ()
{
    document.body.classList.remove('overflow-auto');
}

export function boldString(str, find) {
    if (!find) return str
    const re = new RegExp(find, 'gi');
    return str.replace(re, '<b>$&</b>');
}

export const sanitizedData = (str, find) => ({__html: purify.sanitize(boldString(str, find), { USE_PROFILES: { html: true } })})

export const notificationsError = (response, arrayCodeAndMessages = []) => {
    for (let { code, messages } of arrayCodeAndMessages) {
        if ( response.status === code){
            return NotificationManager.error(messages)
        }
    }
    return NotificationManager.error(response.data.message)
}

export const checkAuth = (link) => {
    setCookie(REDIRECT_COOKIES, link)
    if (+localStorage.getItem(AUTH_LOCALSTORAGE) > moment().millisecond())
        return window.location = link;
    return observable.update(OPEN_LOGIN);
}
