import React from 'react';
import {FormGroup, InputGroup, Label} from "reactstrap";
import {joinClassNames} from "../../../helpers/Utils";
import {Field} from "formik";
import FormErrors from "./FormErrors";
import InputGroupIcon from "./InputGroupIcon";

const VERMILION = "#e22020"

const FormikFormGroup = ({
                             errors,
                             touched,
                             fieldName,
                             placeholder,
                             values,
                             icon,
                             type = 'text',
                             disabled = false,
                             isReversePlaceError = false
                         }) => {
    const isInvalid = errors[fieldName] && touched[fieldName];
    const className = joinClassNames("form-control bottom-line-only", isInvalid && "is-invalid");
    return (
        <FormGroup>
            {isReversePlaceError && <FormErrors className='ml-5 d-flex justify-content-start' fieldName={fieldName} errors={errors} touched={touched} />}
            {!isInvalid && isReversePlaceError && values[fieldName] &&
                <Label className='ml-5 d-flex justify-content-start text-gray'>{placeholder}</Label>}
            {!!icon ? (
                <InputGroup addontype="prepend" className="with-icon">
                    <InputGroupIcon icon={icon} fill={isInvalid ? VERMILION : ''}/>
                    <Field
                        type={type}
                        className={className}
                        name={fieldName}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </InputGroup>
            ) : (
                <Field
                    type={type}
                    className={className}
                    name={fieldName}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            )}
            {!isReversePlaceError && <FormErrors fieldName={fieldName} errors={errors} touched={touched}/>}
        </FormGroup>
    );
};

export default FormikFormGroup;