import {WORKOUTS, COACHING_PLANS, TRAINERS, BECOME_TRAINER} from "./links";

const data = [
    {
        id: "workouts",
        label: "Workout Programs",
        to: WORKOUTS
    },
    {
        id: "plans",
        label:"Coaching Plans",
        to: COACHING_PLANS
    },
    {
        id: "trainers",
        label: "Browse Trainers",
        to: TRAINERS
    },
    {
        id: "about",
        label: "How it Works",
        to: "/about"
    },
    {
        id: "trainer",
        label: "Become a Trainer",
        to: BECOME_TRAINER
    }
];
export default data;