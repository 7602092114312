import React from "react";
import {useClosePressEsc} from "../../hooks/useClosePressEsc";
import {addScroll, removeScroll} from "../../helpers/Utils";
import {Button, Modal} from "reactstrap";

const PopupWrapper = (
    {
        onActive,
        toggleMenu,
        children,
        centered= true,
        unmountOnClose = true,
        showButton = true,
        external = "",
        className
    }) => {
    useClosePressEsc(() => toggleMenu(false), onActive);
    return (
        <Modal
            toggle={toggleMenu}
            isOpen={onActive}
            centered={centered}
            onClosed={addScroll}
            onOpened={removeScroll}
            external={external}
            unmountOnClose={unmountOnClose}
            className={className}
        >
            {showButton && (
                <Button className="close external" onClick={toggleMenu}>
                    &times;
                </Button>
            )}
            {children}
        </Modal>
    );
};

export default PopupWrapper;