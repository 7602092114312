import { useRouter } from "next/dist/client/router"
import { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { joinClassNames } from "../../helpers/Utils"
import useDebounce from "../../hooks/useDebounse"
import { BaseSelect } from "../filters/BaseSelect" 
import {DEFAULT_QUERY} from "../../const/links";

const options = [
    {
      label: 'Trainers',
      value: '/landing/trainers',
    },
    {
      label: 'Coaching Plans',
      value: '/landing/coaching-plans',
    },
    {
      label: 'Workout Programs',
      value: '/landing/workout-programs',
    }
  ]

const SearchLanding = ({className}) => {
    const router = useRouter()
    const [query, setQuery] = useState()
    const debouncedSearch = useDebounce(query, 500)

    const handleSelect = async (link) => {
      await router.push(link);
      setQuery()
    }

    const handleSearch = () => {
      const filterEmpty = Object.keys(router.query).length === 0;
      const isURLQuery = !!query;
      const search = (query) => router.push({query: query})

      if (filterEmpty && isURLQuery) return search({ ...DEFAULT_QUERY, query}) 

      if(!filterEmpty && isURLQuery) return search({ ...router.query, query})

      if (!filterEmpty && !isURLQuery){
        delete router.query.query;
        return search({ ...router.query})
      }
    }

    useEffect(() => {
        handleSearch()
    }, [debouncedSearch])

    return(
            <section className={joinClassNames("landing-search", className)}>
              <BaseSelect
                options={options}
                onChange={handleSelect}
                value={router.pathname}
                className="d-none d-md-block"
              />
        
              <input
                value={query || ''}
                onChange={event => setQuery(event.target.value)}
              />
        
              <Button
                color="primary"
                onClick={handleSearch}
                className="btn--search"
              >
                <span className="d-none d-sm-block">Search</span>
              </Button>
            </section>
    )
}

export default SearchLanding