class Observable {

    handlers = {};
    constructor() {
        this.handlers = {}
    }

    set(key, val) {
        const handlers = this.handlers;
        (handlers[key] || []).forEach((handler) => handler(val));
    }

    subscribe(key, func) {
        let handlers = this.handlers[key];
        if (!handlers) {
            handlers = this.handlers[key] = [];
        }

        handlers.push(func);
        return () => this.unsubscribe(key, func);
    }

    unsubscribe(key, func) {
        this.handlers[key] = (this.handlers[key] || []).filter((handler) => handler !== func);
    }

    update(key){
        this.handlers[key].forEach((func) => func());
    }
}

const observable = new Observable();
export default observable
