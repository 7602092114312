import React from 'react';
import {Formik} from "formik";
import {FormGroup, Form, Button, ModalBody} from "reactstrap";
import FormikFormGroup from "./formik/FormikFormGroup"
import * as Yup from 'yup'
import CheckButton from "../filters/components/CheckButton"
import {password, email, profile} from "../../const/icons";
import {
    FIRST_NAME_MAX_LENGTH,
    FIRST_NAME_MIN_LENGTH,
    IS_INCORRECT_FIRST_NAME,
    IS_INCORRECT_LAST_NAME,
    IS_INCORRECT_NICK_NAME,
    IS_INCORRECT_NICK_NAME_LENGTH,
    IS_INCORRECT_PASSWORD,
    IS_MANDATORY_FIRST_NAME, IS_MANDATORY_LAST_NAME,
    IS_NOT_VALID_FORMAT,
    IS_REQUIRED_FIELD,
    IS_REQUIRED_PASSWORD, LAST_NAME_MAX_LENGTH,
    LAST_NAME_MIN_LENGTH,
    NICKNAME_MAX_LENGTH,
    NICKNAME_MIN_LENGTH,
    PASSWORD_DOES_NOT_MATCH,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    PASSWORD_PATTERN
} from "../../const/message";

import styles from './modal.module.scss'
import Link from "next/link";
import {register, updateTimezoneOffset} from "../../services/WorkoutProgramService";
import {setCookie} from "../../helpers/cookies";
import {REDIRECT_COOKIES, SESSIONS_COOKIES, USER_COOKIES} from "../../const/storege";
import {BASE_REDIRECT_TO_APP} from "../../const/services/API";
import appRedirectsValue from "../../const/services/appRedirectsValue";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(IS_NOT_VALID_FORMAT)
        .required(IS_REQUIRED_FIELD),
    password: Yup.string()
        .min(PASSWORD_MIN_LENGTH, IS_INCORRECT_PASSWORD)
        .max(PASSWORD_MAX_LENGTH, IS_INCORRECT_PASSWORD)
        .matches(PASSWORD_PATTERN, IS_INCORRECT_PASSWORD)
        .required(IS_REQUIRED_PASSWORD),
    nickname: Yup.string()
        .trim()
        .min(NICKNAME_MIN_LENGTH, IS_INCORRECT_NICK_NAME_LENGTH)
        .max(NICKNAME_MAX_LENGTH, IS_INCORRECT_NICK_NAME)
        .required(IS_REQUIRED_FIELD),
    confirmPassword: Yup
        .string()
        .oneOf([Yup.ref("password")], PASSWORD_DOES_NOT_MATCH)
        .required(PASSWORD_DOES_NOT_MATCH),
    firstName: Yup
        .string()
        .trim()
        .min(FIRST_NAME_MIN_LENGTH, IS_INCORRECT_FIRST_NAME)
        .max(FIRST_NAME_MAX_LENGTH, IS_INCORRECT_FIRST_NAME)
        .required(IS_MANDATORY_FIRST_NAME),
    lastName: Yup
        .string()
        .trim()
        .min(LAST_NAME_MIN_LENGTH, IS_INCORRECT_LAST_NAME)
        .max(LAST_NAME_MAX_LENGTH, IS_INCORRECT_LAST_NAME)
        .required(IS_MANDATORY_LAST_NAME),
    accepted: Yup.boolean()
});

const initialValues = {
    firstName: "",
    lastName: "",
    nickname: "",
    email: "",
    password: "",
    confirmPassword: "",
    accepted: false
};

const onUserLogin = values => register(values)
    .then(({ session, user }) => {
        setCookie(REDIRECT_COOKIES, appRedirectsValue.register)
        setCookie(SESSIONS_COOKIES, session);
        setCookie(USER_COOKIES, { id: user.id })
        updateTimezoneOffset(session.accessToken)
        setTimeout(() => window.location = BASE_REDIRECT_TO_APP, 1000)
    })

const SingUp = ({toggle}) => {
    return (
        <div className='auth__modal'>
            <ModalBody className="text-center">
                <h3 className={styles.signUp + ' ' + styles.modal__head}>Sign Up</h3>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onUserLogin}>
                    {(form) => {
                        const {values, errors, touched, isValid, handleSubmit} = form;
                        const hasError = !isValid || !values.accepted;

                        return (
                            <Form className="w-100" onSubmit={handleSubmit}>
                                <FormikFormGroup
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    fieldName="firstName"
                                    placeholder="First Name"
                                    icon={profile}
                                />
                                <FormikFormGroup
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    fieldName="lastName"
                                    placeholder="Last Name"
                                    icon={profile}
                                />
                                <FormikFormGroup
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    fieldName="nickname"
                                    placeholder="Nickname"
                                    icon={profile}
                                />
                                <FormikFormGroup
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    fieldName="email"
                                    placeholder="Email"
                                    icon={email}
                                />
                                <FormikFormGroup
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    fieldName="password"
                                    placeholder="Password"
                                    icon={password}
                                    type="password"
                                />
                                <FormikFormGroup
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    fieldName="confirmPassword"
                                    placeholder="Confirm password"
                                    icon={password}
                                    type="password"
                                />
                                <FormGroup>
                                    <label className="filter__checkbox">
                                        <CheckButton
                                            name="accepted"
                                            id="accepted"
                                            onChange={() => values.accepted = !values.accepted}
                                        />
                                        <span className={styles.checkbox__label__with__link}>
                                            <div>
                                                By signing up, you agree to our
                                                <br /> <Link href={`#terms`}>Terms
                                                of Service</Link> and <Link href={`#policy`}>Privacy
                                                Policy</Link>
                                            </div>
                                        </span>
                                    </label>
                                </FormGroup>
                                <div className="d-flex justify-content-center align-items-center">
                                    <Button color="primary"
                                            disabled={hasError}
                                            className={styles.btn}
                                            size="lg"
                                            type="submit"
                                    >
                                        Sign Up
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <div className={styles.modal__footer}>
                    Already have an account?
                    <Button 
                        color="link" 
                        onClick={toggle}
                        className={styles.btn__link}
                    >
                        Sign In
                    </Button>
                </div>
            </ModalBody>
        </div>
    );
};

export default SingUp;