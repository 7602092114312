import React, {useState} from 'react';
import {Button, ModalBody} from "reactstrap";
import {Form, Formik} from "formik";
import * as Yup from 'yup';

import {useModal} from "../../hooks/useModal";
import FormikFormGroup from "./formik/FormikFormGroup";
import {INCORRECT_AUTH, INCORRECT_EMAIL, IS_NOT_VALID_FORMAT, IS_REQUIRED_FIELD} from "../../const/message";
import {forgotPassword} from "../../services/WorkoutProgramService";
import {email} from "../../const/icons";
import styles from "./modal.module.scss";
import {joinClassNames, notificationsError} from "../../helpers/Utils";

const validationsSchema =  Yup.object().shape({
    email: Yup.string()
        .email(IS_NOT_VALID_FORMAT)
        .required(IS_REQUIRED_FIELD),
})

const DoneForgotPage = ({onClick, email}) => {
    return (
        <ModalBody>
            <div className='label-done'>Your password reset instructions have been sent to your email address</div>
            <div className='email'>{email}</div>
            <div className='btn-panel justify-content-center'>
                <Button
                    color='primary'
                    className={joinClassNames(styles.btn, 'mx-auto')}
                    onClick={onClick}
                >
                    Done
                </Button>
            </div>
        </ModalBody>
    );
};

const ForgotPassword = ({ onDoneClick }) => {
    const {isOpen: donePage, toggle: toggleDonePage} = useModal();
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

    const onSubmit = ({ email }, { setFieldError }) => forgotPassword(email)
        .then(toggleDonePage)
        .catch(({ response}) => {
            notificationsError(response, INCORRECT_AUTH)
            setFieldError('email', INCORRECT_EMAIL)
        })
        .finally(() => setForgotPasswordEmail(email))

    if (donePage) {
        return (
            <DoneForgotPage
                onClick={onDoneClick}
                email={forgotPasswordEmail}
            />
        )
    }
    return (
        <ModalBody>
            <div className='title mb-3 text-center'>Forgot Password?</div>
            <div className='label'>Enter your email to reset password</div>
            <Formik
                initialValues={{email: ''}}
                validationSchema={validationsSchema}
                onSubmit={onSubmit}
            >
                {({values, errors, touched, isSubmitting}) => {
                    return (
                        <Form className="w-100">
                            <FormikFormGroup
                                errors={errors}
                                values={values}
                                className="mb-4 mt-1"
                                touched={touched}
                                fieldName="email"
                                placeholder="Email"
                                icon={email}
                            />
                            <div className='btn-panel'>
                                <Button
                                    color='primary'
                                    type='submit'
                                    disabled={isSubmitting}
                                    className={joinClassNames(styles.btn, 'mx-auto')}
                                >
                                    Send
                                </Button>
                            </div>

                        </Form>)}
                }
            </Formik>
        </ModalBody>
    );
};

export default ForgotPassword;