import {ASC, DESC} from "./services/API";

export const POPULAR_DESC = 'popular desc';
const UPDATED_AT_DESC = 'updatedAt desc';
const RATING_DESC = 'rating desc';

export const DEFAULT_SORTING = {orderBy: 'createdAt', orderType: DESC}

export const DEFAULT_SORT_OPTIONS = [
    { value: POPULAR_DESC, label: 'Popular'},
    { value: UPDATED_AT_DESC, label: 'Newest'},
];

export const TRAINER_OFFERS = [
    { label: 'Workout Programs', value: 1 },
    { label: 'Coaching Plans', value: 2 }
];
export const CERTIFICATE_OPTIONS = [
    {
        label: 'test authority 1',
        value: 'test authority 1',
    },
    {
        label: 'test authority 2',
        value: 'test authority 2',
    },
    {
        label: 'test authority 3',
        value: 'test authority 3',
    }
];

export const RATING_VALUE_ARRAY = [4, 3, 2, 1];

export const ADVANCED_SORTING = [
    ...DEFAULT_SORT_OPTIONS,
    { value: RATING_DESC, label: 'Rating'},
    { value: 'price ' + ASC, label: 'Price'},
];

export const WORKOUT_SORTING = [
    { value: POPULAR_DESC, label: 'Most Popular'},
    { value: UPDATED_AT_DESC, label: 'Newest'},
    { value: RATING_DESC, label: 'Rating'},
    { value: 'price ' + ASC, label: 'Price: Low - High'},
    { value: 'price ' + DESC, label: 'Price: High - Low'},
];

export const QUESTION_SORTING = [
    {value: `repliedAt ${DESC}`, label: 'Newest to Oldest'},
    {value: `repliedAt ${ASC}`, label: 'Oldest to Newest'},
];

export const REVIEWS_SORTING = [
    {value: `createdAt ${DESC}`, label: 'Newest to Oldest'},
    {value: `createdAt ${ASC}`, label: 'Oldest to Newest'},
];

export const STAR_FILTER = [
    {value: undefined, label: 'All', chipLabel: 'Stars'},
    ...['5', '4', '3', '2'].map(value => ({value, label: `${value} Stars`, chipLabel: `${value} Stars`})),
    {value: '1', label: '1 Star', chipLabel: '1 Star'}
]

export const EXPERIENCE = [
    {
        label: 'Beginner',
        value: 1
    },
    {
        label: 'Intermediate',
        value: 2
    },
    {
        label: 'Advanced',
        value: 3
    }
];

export const PROGRAM_CATEGORY = [
    {
        label: 'Strength and Resistance Training',
        value: 1
    },
    {
        label: 'Circuit and Endurance Training',
        value: 2
    },
    {
        label: 'High Intensity Intervals Training',
        value: 3
    },
    {
        label: 'Calisthenics and Body Weight',
        value: 4
    },
];

export const REPORT_TYPE = {
    policyViolation: 1,
    offensiveContent: 2,
    spam: 3,
    other: 4
}

export const REPORT_TYPE_OPTIONS = [
    { value: REPORT_TYPE.policyViolation, label: "Policy Violation" },
    { value: REPORT_TYPE.offensiveContent, label: "Offensive Content" },
    { value: REPORT_TYPE.spam, label: "Spam" },
    { value: REPORT_TYPE.other, label: "Other" },
];

export const STATUS_WORKOUT = {
    active: 1,
    blocked: 2,
    inactive: 3,
    draft: 4
}

export const MIN_PRICE = 1;
export const MAX_PRICE = 10000;
export const MIN_DURATION = 1;
export const MAX_DURATION = 150;

export const START_PRICE_WORKOUT = 5;
