import React from 'react';

const IconSvg = ({width, height, fill = false, d}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <path fill={!!fill ? fill : "#08AAF5"} d={d}/>
        </svg>
    );
};

export default IconSvg;