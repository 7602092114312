import React from 'react';
import {Button, ModalBody} from "reactstrap";
import Image from "next/image";
import warning from '../../icons/landing/icons/ic_warning_thriangle.svg';
import styles from "./modal.module.scss";

const AccountBlocked = ({toggle}) => {
    return (
        <ModalBody className="text-center account-blocked-modal">
            <Image src={warning} alt={'warning'} />
            <h2 className="my-3 title">Account Blocked</h2>
            <p className='text mx-auto'>Your account has been blocked. Please check your email for additional details.</p>

            <Button onClick={ toggle } className={styles.btn} color='primary'>Close</Button>
        </ModalBody>
    );
};

export default AccountBlocked;