import React from 'react';
import {Button, FormGroup, ModalBody} from "reactstrap";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {IS_REQUIRED_OTP} from "../../const/message";
import {password} from "../../const/icons";
import FormikFormGroup from "./formik/FormikFormGroup";
import CheckButton from "../filters/components/CheckButton";
import styles from "./modal.module.scss";
import {twoStepVerify, updateTimezoneOffset} from "../../services/WorkoutProgramService";
import {setCookie} from "../../helpers/cookies";
import {SESSIONS_COOKIES} from "../../const/storege";
import {BASE_REDIRECT_TO_APP} from "../../const/services/API";
import TimerButton from "../TimerButton";

const validationSchema = Yup.object().shape({
    code: Yup.string().required(IS_REQUIRED_OTP)
})

const onLogin = (values, sessions, setFieldError) =>
    twoStepVerify(values, sessions.accessToken).then(({ session }) => {
        setCookie(SESSIONS_COOKIES, session);
        updateTimezoneOffset(session.accessToken)
        setTimeout(() => window.location = BASE_REDIRECT_TO_APP, 1000)

    }).catch((error) => setFieldError('code', error.response.data?.errors[0]?.message))


const countLastNumber = 3

const getNumberText = (phone, countLastNumber) => {
    return `Please enter the One Time Password (OTP) that has been sent to a phone number ending in ${phone.slice(-countLastNumber)}`
}

const TwoStepVerify = ({phone, sessions, onResendClick}) => {
    return (
        <div className='auth__modal second-verify'>
            <ModalBody className="text-center">
                <div className='title'>Two-Step Verification</div>
                <div className='text'>{getNumberText(phone, countLastNumber)}</div>

                <Formik
                    initialValues={{code: '', isRemember: false}}
                    onSubmit={(values, {setFieldError}) => onLogin(values, sessions, setFieldError)}
                    validationSchema={validationSchema}
                >
                    {form => {
                        const { values, errors, touched, handleSubmit } = form;

                        return (
                            <Form
                                className="second-verify-form"
                                onSubmit={handleSubmit}
                            >
                                <FormikFormGroup
                                    errors={errors}
                                    values={values}
                                    className="mb-4 mt-1"
                                    touched={touched}
                                    fieldName="code"
                                    placeholder="Enter OTP"
                                    icon={password}
                                    isReversePlaceError
                                />
                                <FormGroup className='d-flex align-items-baseline justify-content-between'>
                                    <label className="filter__checkbox">
                                        <CheckButton
                                            name="isRemember"
                                            id="isRemember"
                                            onChange={() => values.isRemember = !values.isRemember}
                                        />
                                        <span className={styles.checkbox__label}>
                                            Don’t require OTP on this browser
                                        </span>
                                    </label>

                                    <TimerButton
                                        label='Resend SMS'
                                        onClick={onResendClick}
                                        onRenderStartTimer
                                        className='p-0'
                                    />
                                </FormGroup>

                                <div className="d-flex justify-content-center align-items-center">
                                    <Button
                                        color="primary"
                                        className={styles.btn}
                                        size="lg"
                                        type="submit"
                                    >
                                        Sign In
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </div>
    );
};

export default TwoStepVerify;