import axios from "axios";
import {ASC, COACHING, DEFAULT_ORDER, DESC, TRAINERS, WORKOUT} from "../const/services/API";
import {DEFAULT_LIMIT_FILTER, DEFAULT_LIMIT_QUESTIONS, DEFAULT_OFFSET, DEFAULT_LIMIT_PHOTO} from "../const/services/defaultValue";
import {formatPriceToCents} from "../helpers/Utils";
import replyFilter from "../const/services/replyFilter";
import {DAYS_IN_WEEK, HOURS_IN_DAY, MINUTES_IN_HOUR, SECOND_IN_MINUTES} from "../const/time";
import {AUTH_LOCALSTORAGE} from "../const/storege";
import {DEFAULT_SORTING} from "../const/filters";
import moment from "moment";
import photoStatus from "../const/photoStatus";
import role from "../const/role";

export const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_API
});

export const addBearer = (token) => ({headers: {
        'Authorization': `Bearer ${token}`
    }})

export async function getEquipments(query = undefined) {
    const response = await instance.get('/equipments', {params: {query}});
    return response.data
}

export async function getWorkoutList(queres) {
    const {
        offset = 0,
        orderBy = DEFAULT_ORDER,
        levels = [],
        modalities = [],
        equipmentIds = [],
        duration = [],
        price = [],
        ratingFrom = undefined,
        orderType = DESC,
        query = undefined,
    } = queres;
    const response = await instance.get(WORKOUT, {
        params: {
            offset: offset,
            limit: DEFAULT_LIMIT_FILTER,
            orderBy: orderBy,
            orderType: orderType,
            levels: levels,
            modalities: modalities,
            durationFrom: duration[0] || undefined,
            durationTo: duration[1] || undefined,
            priceFrom: formatPriceToCents( price[0] ),
            priceTo: formatPriceToCents( price[1] ),
            equipmentIds: equipmentIds,
            ratingFrom: ratingFrom,
            query: query
        }
    });
    return response.data;
}

export async function getWorkout(id) {
    const response = await instance.get(`${WORKOUT}/${id}`);
    return response.data;
}

export async function getCoachingPlan(id) {
    const response = await instance.get(`${COACHING}/${id}`);
    return response.data;
}

export async function getCoachingPlans(queres) {
    const {
        offset = 0,
        orderBy = DEFAULT_ORDER,
        levels = [],
        consultationsCount = [],
        ratingFrom = undefined,
        hasFreeAssessmentConsultations = undefined,
        price = [],
        orderType = DESC,
        query = undefined,
        customNutritionPlan,
    } = queres;
    const response = await instance.get(COACHING, {
        params: {
            offset: offset,
            limit: DEFAULT_LIMIT_FILTER,
            orderBy: orderBy,
            orderType: orderType,
            levels: levels,
            consultationsCount: consultationsCount,
            ratingFrom: ratingFrom,
            hasFreeAssessmentConsultations: hasFreeAssessmentConsultations,
            priceFrom: formatPriceToCents( price[0] ),
            priceTo: formatPriceToCents( price[1] ),
            query,
            customNutritionPlan,
        }
    });
    return response.data
}

export async function getTrainers(queres) {
    const {
        orderBy = DEFAULT_ORDER,
        orderType = DESC,
        offers = undefined,
        certificateAuthorities = [],
        ratingFrom = undefined,
        offset = 0,
        query = undefined
    } = queres;
    const response = await instance.get(TRAINERS, {
        params: {
            offset: offset,
            limit: DEFAULT_LIMIT_FILTER,
            orderBy: orderBy,
            orderType: orderType,
            offers: offers,
            certificateAuthorities: certificateAuthorities,
            ratingFrom: ratingFrom,
            query: query
        }
    });
    return response.data;
}

export async function getTrainer(id) {
    const response = await instance.get(`${TRAINERS}/${id}`);
    return response.data
}

export async function getTrainerByUrl(url) {
    const response = await instance.get(`${TRAINERS}/storefront-url/${url}`);
    return response.data
}

export async function getQuestions(params) {
    const {
        url,
        id,
        offset = DEFAULT_OFFSET,
        limit = DEFAULT_LIMIT_QUESTIONS,
        search,
        orderBy = 'repliedAt',
        orderType = DESC
    } = params;

    const reqParams = {
        limit,
        offset,
        orderBy,
        orderType,
        replyFilter: replyFilter.withReply,
        query: search,
    };

    const response = await instance.get(`${url}/${id}/questions`, {
        params: reqParams
    });
    
    return response.data;
}

export async function getReviews(params) {
    const {
        url,
        id,
        offset = 0,
        limit = DEFAULT_LIMIT_QUESTIONS,
        ratingFilter,
        orderBy = DEFAULT_SORTING.orderBy,
        orderType = DEFAULT_SORTING.orderType
    } = params;

    const response = await instance.get(`${url}/${id}/reviews`, {
        params: {
            limit,
            offset,
            orderBy,
            orderType,
            ratingFilter
        }
    });

    return response.data;
}

export async function getReviewsStarts(params) {
    const {url, id} = params;
    const response = await instance.get(`${url}/${id}/reviews/counts`);
    return response.data;
}

export async function postReport(url, id, values){
    return await instance.post(`${url}/${id}/reports`, values);
}

export async function getTrainerExperience(id) {
    const response = await instance.get(`${TRAINERS}/${id}/experience`);
    return response.data;
}

export async function getTrainerCertificates(id) {
    const response = await instance.get(`${TRAINERS}/${id}/certificates`)
    return response.data;
}

export async function getTrainerWorkoutList(id) {
    const response = await  instance.get(`${TRAINERS}/${id}${WORKOUT}`, {
        params: {
            limit: DEFAULT_LIMIT_FILTER,
            orderBy: 'position',
            orderType: ASC,
        }
    });
    return response.data;
}

export async function getTrainerCoachingPlans(id) {
    const response = await instance.get(`${TRAINERS}/${id}${COACHING}`, {
        params: {
            limit: DEFAULT_LIMIT_FILTER
        }
    });
    return response.data
}

export async function login({ email, password, remember}) {
    const refreshTokenLifeTime = HOURS_IN_DAY * MINUTES_IN_HOUR * SECOND_IN_MINUTES * DAYS_IN_WEEK;
    const tokenLifeTime = remember ? refreshTokenLifeTime : refreshTokenLifeTime / DAYS_IN_WEEK;

    localStorage.setItem(AUTH_LOCALSTORAGE, (moment().millisecond() + tokenLifeTime).toString());

    const response = await instance.post('/sessions',
    {
        email,
        password,
        tokenLifeTime,
        refreshTokenLifeTime,
    })
    return response.data.data
}

export async function register(values) {
    const { accepted, confirmPassword, ...rest } = values;

    const refreshTokenLifeTime = HOURS_IN_DAY * MINUTES_IN_HOUR * SECOND_IN_MINUTES * DAYS_IN_WEEK;
    const tokenLifeTime = refreshTokenLifeTime / DAYS_IN_WEEK;

    localStorage.setItem(AUTH_LOCALSTORAGE, (moment().millisecond() + tokenLifeTime).toString());

    const response = await instance.post('/users',{
        ...rest,
        role: role.USER,
        tokenLifeTime,
        refreshTokenLifeTime,
    })
    return response.data.data
}

export async function getTrainerPhoto(
    {
        id,
        limit = DEFAULT_LIMIT_PHOTO,
        offset = DEFAULT_OFFSET
    }) {
    return instance.get(`${TRAINERS}/${id}/progress-photos`, {
        params: {
            limit,
            offset,
            status: photoStatus.displayed
        }
    }).then(({ data }) => data)
}

export async function getProgressPhoto(
    {
        coachingPlanId,
        workoutProgramId,
        limit = DEFAULT_LIMIT_PHOTO,
        offset = DEFAULT_OFFSET
    }
    ) {
    const response = await instance.get('/photo-progress-requests', {
        params: {
            coachingPlanId,
            workoutProgramId,
            isListing: true,
            status: photoStatus.approved,
            orderBy: 'position',
            limit,
            offset,
            orderType: ASC
        }
    })
    return response.data
}

export async function twoStepVerify(values, token) {
    const response = await instance.post('/users/two-factors', values, addBearer(token))
    return response.data.data
}

export function getCode(values) {
    return instance.get('/verifications/phone', values)
}

export function forgotPassword(email) {
    return instance.post(`/verifications/password`, {email})
}


export function updateTimezoneOffset(token) {
    return instance.patch("/user-settings/timezone", {offset: -(new Date().getTimezoneOffset())}, addBearer(token))
}

export function report ({ url, id, value, token }) {
    return instance.post(`${url}/${id}/reports`, value, addBearer(token))
}

export function postQuestion ({ url, id, value, token }) {
    return instance.post(`${url}/${id}/questions`, value, addBearer(token))
}