import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Notification from './Notification';
import {joinClassNames} from '../../helpers/Utils';
import { noOperation } from '../../helpers/Utils';
import moment from "moment";

const Notifications = ({
  notifications = [],
  onRequestHide = noOperation,
  enterTimeout = 400,
  leaveTimeout = 400,
}) => {
  const handleRequestHide = notification => {
    onRequestHide(notification);
  };

  return (
    <div className={joinClassNames(
      "notification-container",
      notifications.length === 0 && "notification-container-empty"
    )}>
      <TransitionGroup>
        {notifications.map((notification) => {
          const key = notification.id || moment().valueOf();
          return (
            <CSSTransition
              classNames="notification"
              key={key}
              timeout={{ exit: leaveTimeout, enter: enterTimeout }}
            >
              <Notification
                key={key}
                type={notification.type}
                title={notification.title}
                message={notification.message}
                timeOut={notification.timeOut}
                onClick={notification.onClick}
                onRequestHide={() => handleRequestHide(notification)}
                customClassName={notification.customClassName}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  onRequestHide: PropTypes.func,
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number
};

export default Notifications;
