import React from "react";
import styles from "./Loading.module.scss";

function Loading({loading}) {
    return (
        <div className={loading ? styles.wrapper : styles.none}>
            <div className={ styles.loading }/>
        </div>
    );
}

export default Loading;