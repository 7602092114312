import {DESC, DEFAULT_ORDER} from "./services/API";

export const DEFAULT_QUERY = {
    offset: 0, 
    orderBy: DEFAULT_ORDER, 
    orderType: DESC, 
    query: '',
};
export const WORKOUTS = "/landing/workout-programs";
export const COACHING_PLANS = "/landing/coaching-plans";
export const TRAINERS = "/landing/trainers";
export const BECOME_TRAINER = process.env.NEXT_PUBLIC_BASE_REDIRECT_TO_APP + '/auth/register';