import React from 'react';
import styles from './footer.module.scss'
import {Container, Row} from "reactstrap";
import Image from "next/legacy/image";
import Colxx from "../Colxx";

import IcLogo from "../../icons/home/ic-logo-medium.svg"
import appstoreBtn from "../../icons/buttons/btn-appstore@2x.png"
import playstoreBtn from "../../icons/buttons/btn-playstore@2x.png"

import twitterBtn from "../../icons/buttons/img-socials-twitter@2x.png"
import facebookBtn from "../../icons/buttons/img-socials-facebook@2x.png"
import instagrammBtn from "../../icons/buttons/img-socials-instagramm@2x.png"
import Link from "next/link";
import {COACHING_PLANS, DEFAULT_QUERY, WORKOUTS} from "../../const/links";
import moment from "moment";

const forClients = [
    {href: WORKOUTS, label: "Workout Programs"},
    {href: COACHING_PLANS, label: "Coaching Plans"},
    {href: "/", label: "Lorem Ipsum"},
    {href: "/", label: "Dolor Sit Amet"}
];

const forTrainers = [
    {href: "https://dev.trainer.trainest.com/auth/register", label: "Become a Trainer"},
    {href: "/", label: "Lorem Ipsum"},
    {href: "/", label: "Lorem Ipsum"},
    {href: "/", label: "Dolor Sit Amet"}
];

const forCompany = [
    {href: "/", label: "Lorem Ipsum"},
    {href: "/", label: "Lorem Ipsum"},
    {href: "/", label: "Dolor Sit Amet"},
    {href: "/", label: "Lorem Ipsum"},
];

const Col = ({arrayLinks, title}) => {
    return (
        <Colxx lg="2" md="3" xxs="6" className="d-flex flex-column">
            <h3 className={styles.footerTitle}>{title}</h3>
            {arrayLinks.map(({href, label}) => <Link key={href} href={{pathname: href, query: DEFAULT_QUERY}} legacyBehavior>{label}</Link>)}
        </Colxx>
    );
};

const SocialNetwork = ({src, href, alt, width=44, height=44}) => {
    return (
        <a href={href}>
            <Image
                src={src}
                alt={alt}
                width={width}
                height={height}
            />
        </a>
    )
};

const Footer = () => {
    return (
        <div className={styles.footer}>
            <Container>
                <Row>
                    <Colxx lg="4" className="flex-column">
                        <Image
                            src={IcLogo}
                            alt="Trainest Logo"
                            width={130}
                            height={32}
                        />
                        <p className={styles.paragraph}>
                            Lorem ipsum dolor sit amet, consectetur adipisci
                            elit. Suspendisse gravida efficitur est qui.
                        </p>
                        <div className={styles.stores}>
                            <a href="https://www.apple.com/ru/ios/app-store/">
                                <Image
                                    src={appstoreBtn}
                                    alt="appstore"
                                    width={155}
                                    height={45}
                                />
                            </a>

                            <a href="https://play.google.com">
                                <Image
                                    src={playstoreBtn}
                                    alt="playstore"
                                    width={155}
                                    height={45}
                                />
                            </a>
                        </div>
                    </Colxx>
                    <Col arrayLinks={forClients} title={"For Clients"}/>
                    <Col arrayLinks={forTrainers} title={"For Trainers"}/>
                    <Col arrayLinks={forCompany} title={"Company"}/>
                    <Colxx lg="2" md="3" xxs="6" className="d-flex flex-column">
                        <h3 className={styles.footerTitle}>Get in Touch</h3>
                        <Link href="/">Customer Support</Link>
                        <Link href="/">Contact Us</Link>
                        <div className={styles.footerSocials}>
                            <SocialNetwork
                                href={"https://twitter.com"}
                                src={twitterBtn}
                                alt={"twitter"}
                            />
                            <SocialNetwork
                                href={"https://facebook.com"}
                                src={facebookBtn}
                                alt={"facebook"}
                            />
                            <SocialNetwork
                                href={"https://instagram.com"}
                                src={instagrammBtn}
                                alt={"instagram"}
                            />
                        </div>
                    </Colxx>
                </Row>
            </Container>

            <div className={styles.footerCopyright}>
                {`Trainest © ${moment().format('YYYY')}. All Rights reserved`}
            </div>
        </div>
    );
};

export default Footer;