import {HOURS_IN_DAY, MINUTES_IN_HOUR, SECOND_IN_MINUTES} from "../const/time";

export function setCookie(name, value){
    const lifeTimeToken = (new Date(Date.now()+ SECOND_IN_MINUTES * MINUTES_IN_HOUR * HOURS_IN_DAY * 1000)).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(JSON.stringify(value))}; expires=${lifeTimeToken}; domain=trainest.com; path=/; secure`
}

export function getCookie(name) {
    if ( process.browser ) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches && matches[1] ? JSON.parse(decodeURIComponent(matches[1])) : undefined;
    }
    return {}
}