import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {joinClassNames} from "../../helpers/Utils";
import { noOperation } from '../../helpers/Utils';

const Notification = ({
  type = 'info',
  title = null,
  message = null,
  timeOut = 5000,
  onClick = noOperation,
  onRequestHide = noOperation,
  customClassName = '',
}) => {
  const timer = useRef();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    onRequestHide();
  };

  useEffect(() => {
    if (timeOut !== 0) {
      timer.current = setTimeout(onRequestHide, timeOut);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    }
  }, [])


  return (
    <div className={joinClassNames(`notification notification-${type}`, customClassName)} onClick={handleClick}>
      <div className="notification-message" role="alert">
        {!!title && <h4 className="title">{title}</h4>}
        <div className="message">{message}</div>
      </div>
    </div>
  );
}

Notification.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error', "primary", "secondary"]),
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
  timeOut: PropTypes.number,
  onClick: PropTypes.func,
  onRequestHide: PropTypes.func,
  customClassName: PropTypes.string,
}

export default Notification;
