import React from 'react';

const CheckButton = ({onChange, checked, value, name}) => {
    return (
        <input
            type="checkbox"
            onChange={onChange}
            value={value}
            name={name}
            checked={checked}
        />
    );
};

export default CheckButton;