export const password = {
    width: 18,
    height: 21,
    d: "M12 12.25c-1.333-.005-2.625-.467-3.67-1.313l-2.606 2.424 2.847 2.914-1.2 1.225-2.904-2.975-2.337 2.172 3.021 3.074-1.2 1.225L0 16.975l.06-.061c-.106-.3-.023-.634.21-.846l6.866-6.387c-1.603-2.3-1.456-5.42.357-7.552C9.305-.003 12.31-.591 14.767.706c2.456 1.297 3.723 4.142 3.065 6.884-.657 2.741-3.067 4.667-5.832 4.66zm0-10.5c-2.367 0-4.286 1.959-4.286 4.375S9.634 10.5 12 10.5c2.367 0 4.286-1.959 4.286-4.375S14.366 1.75 12 1.75z"
};
export const email = {
    width: 22,
    height: 18,
    d: "M22 17.1c0 .496-.41.9-.917.9H.917C.41 18 0 17.595 0 17.1V.9.88C0 .875.006.861.007.848.02.648.1.458.237.308.25.29.244.266.26.25.278.234.313.238.335.22.475.105.647.035.829.019.86.01.894.005.928.002h20.139c.033.003.066.01.099.017.181.016.354.086.494.2.021.019.053.011.073.031.02.02.01.04.024.058.136.15.217.34.23.54 0 .012.006.02.006.033V.9L22 17.1zM1.833 15.3c0 .496.41.9.917.9h16.5c.506 0 .917-.404.917-.9V2.731l-8.49 7.754c-.011.013-.006.034-.019.047-.371.336-.943.336-1.314 0-.013-.013-.008-.034-.02-.047l-8.49-7.754V15.3zM11 8.764L18.485 1.8H3.514L11 8.765z"
};

export const profile = {
    width: 18,
    height: 20,
    d: "M9 12.783c-3.976 0-7.2 2.27-7.2 6.304 0 .504-.403.913-.9.913s-.9-.409-.9-.913c-.002-3.66 2.152-5.967 5.472-7.4-2.294-1.573-3.307-4.481-2.496-7.167C3.786 1.834 6.23 0 9 0c2.768 0 5.213 1.834 6.023 4.52.811 2.686-.202 5.594-2.496 7.167 3.32 1.432 5.475 3.74 5.473 7.4 0 .504-.403.913-.9.913s-.9-.409-.9-.913c0-4.034-3.224-6.304-7.2-6.304zM4.5 6.39c0 2.522 2.015 4.566 4.5 4.566s4.5-2.044 4.5-4.566c0-2.521-2.015-4.565-4.5-4.565-2.484.003-4.498 2.045-4.5 4.565z"
};

export const blurBase64 = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAAAIYAAAAAAIQAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANv/bAEMAAgEBAQEBAgEBAQICAgICBAMCAgICBQQEAwQGBQYGBgUGBgYHCQgGBwkHBgYICwgJCgoKCgoGCAsMCwoMCQoKCv/bAEMBAgICAgICBQMDBQoHBgcKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCv/AABEIAA0AFAMBIgACEQEDEQH/xAAXAAADAQAAAAAAAAAAAAAAAAAABgcJ/8QAIxAAAgICAgICAwEAAAAAAAAAAgMBBAUGBwgSEwARCRQVIf/EABQBAQAAAAAAAAAAAAAAAAAAAAX/xAAhEQABAwQBBQAAAAAAAAAAAAABAAIRAwQSQSETFGGRwf/aAAwDAQACEQMRAD8ATerPWHpzyJ+JN++c10cvV3ZNbZsBx/f17YsyDUGj23YyNqjRsgp1WkVizbsOYH3+vWgIkyhainnMfQvr1w1gNY1Pr3pCORs5td3jnKbPkcJkSvDr+PEKrMtbd4m11Vdl78WZsZNYR/cj0NNZNSlc2/uxz1wv0c4a6w8f7N/O1nda13JZ46Qym4cVdmbHrXYGYYkpIlELAkTUS/IJg/A1y+v355K7k5TkvmTbcQGrDisHVdOC0vINrINLzHGrAGu9r0mpmVu3fMWfTbBgTQZCVQDgtXtuMuI14CE6tr2WIYcwQZ199CJMSeFpn0C7GdSOu/HOa1bmXnPWdMy2Xsa7mv5+w50K1m3FnTtfll0osQDTlzxecsYImZeREIlMxB8xq545wy2L5OenaNMwmxE3BYN9B+XK6TalRmHpMVW8hsx7PAS+pZP+mcmf0MFACfEEVSYadMNOl//Z"