import '../styles/_landing.scss'
import '../styles/bootstrap.min.css'
import '../styles/_product.scss'
import '../styles/_extension.scss'
import '../styles/_trainer-details.scss'
import '../styles/_modals.scss'
import '../styles/_tooltip-and-popover.scss'
import '../styles/_search.scss'
import '../styles/globals.scss'

import Footer from "../components/Footer";
import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Head from "next/head";
import {useRouter} from "next/router";
import Loading from "../components/Loading";
import {NotificationContainer} from '../components/react-notifications';
import {addScroll, closeScroll} from "../components/modal/actions";
import { urlWithoutQuery } from '../helpers/Utils'
import Error from "next/error";

export default function App({Component, pageProps}) {
    const router = useRouter();
    const [loading, setLoading] = useState(true);

    const handleComplete = () => {setLoading(false); closeScroll()};

    const handleStart = (url) => {
        addScroll();
        if(urlWithoutQuery(url) !== urlWithoutQuery(router.asPath)){
            setLoading(true);
        }
        else{
            handleComplete();
        }
    };
    
    useEffect(() => {
        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleComplete);
        router.events.on("routeChangeError", handleComplete);
    }, [router]);

    useEffect(handleComplete, [Component]);
    const getLayout = Component.getLayout || ((page) => {
        return <>
            <Header/>
            {page}
            <Footer/>
        </>})

    if (pageProps.error) {
        return <Error statusCode={pageProps.error.statusCode} />;
    }
        
    return getLayout (
        <>
            <Head>
                <meta charSet="utf-8"/>
                <meta name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"/>
                <meta name="description"
                      content="This is an example of a meta description. This will often show up in search results."/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;1,600&display=swap"
                    rel="stylesheet"/>
                <title>Trainest</title>
                <script type="text/javascript" src="dist/purify.min.js"></script>
            </Head>
            <Loading loading={loading}/>
            <NotificationContainer/>
            <Component {...pageProps}/>
        </>
    )
}